/* eslint-disable import/extensions */
export default [
  {
    path: '/set-golongandarah',
    name: 'set-golongandarah',
    component: () => import('@/views/pengaturan/golongandarah/list/GolonganDarahList'),
    meta: {
      pageTitle: 'Data Golongan Darah',
      breadcrumb: [
        {
          text: 'Data Golongan Darah',
        },
        {
          text: 'Data GolonganDarah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/set-golongandarah/add',
    name: 'set-golongandarah-add',
    component: () => import('@/views/pengaturan/golongandarah/add/GolonganDarahCreate'),
    meta: {
      pageTitle: 'Data Golongan Darah',
      breadcrumb: [
        {
          text: 'Data Golongan Darah',
        },
        {
          text: 'Tambah Data GolonganDarah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/set-golongandarah/edit/:id',
    name: 'set-golongandarah-edit',
    component: () => import('@/views/pengaturan/golongandarah/edit/GolonganDarahEdit'),
    meta: {
      pageTitle: 'Data Golongan Darah',
      breadcrumb: [
        {
          text: 'Data Golongan Darah',
        },
        {
          text: 'Update Data GolonganDarah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/set-golongandarah/:id',
    name: 'set-golongandarah-detail',
    component: () => import('@/views/pengaturan/golongandarah/list/GolonganDarahDetail'),
    meta: {
      pageTitle: 'Data Golongan Darah',
      breadcrumb: [
        {
          text: 'Data Golongan Darah',
        },
        {
          text: 'Detail Data GolonganDarah',
          active: true,
        },
      ],
    },
  },
]
