/* eslint-disable import/extensions */
export default [
  {
    path: '/academic-year',
    name: 'academic-year',
    component: () => import('@/views/pengaturan/tahunakademik/list/tahunakademikList'),
    meta: {
      pageTitle: 'Data Tahun Akademik',
      breadcrumb: [
        {
          text: 'Data Tahun Akademik',
        },
        {
          text: 'Data Tahun Akademik',
          active: true,
        },
      ],
    },
  },
  {
    path: '/academic-year/add',
    name: 'academic-year-add',
    component: () => import('@/views/pengaturan/tahunakademik/add/tahunakademikCreate'),
    meta: {
      pageTitle: 'Data Tahun Akademik',
      breadcrumb: [
        {
          text: 'Data Tahun Akademik',
        },
        {
          text: 'Tambah Data Tahun Akademik',
          active: true,
        },
      ],
    },
  },
  {
    path: '/academic-year/edit/:id',
    name: 'academic-year-edit',
    component: () => import('@/views/pengaturan/tahunakademik/edit/tahunakademikEdit'),
    meta: {
      pageTitle: 'Data Tahun Akademik',
      breadcrumb: [
        {
          text: 'Data Tahun Akademik',
        },
        {
          text: 'Update Data Tahun Akademik',
          active: true,
        },
      ],
    },
  },
  {
    path: '/academic-year/:id',
    name: 'academic-year-detail',
    component: () => import('@/views/pengaturan/tahunakademik/list/tahunakademikDetail'),
    meta: {
      pageTitle: 'Data Tahun Akademik',
      breadcrumb: [
        {
          text: 'Data Tahun Akademik',
        },
        {
          text: 'Detail Data Tahun Akademik',
          active: true,
        },
      ],
    },
  },
]
