import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sessionExpiration: new Date(),
  },
  mutations: {
    setSessionExpiration(state, expiration) {
      state.sessionExpiration = expiration;
    },
  },
  actions: {
    updateSessionExpiration({ commit }) {
      const expiration = new Date();
      expiration.setMinutes(expiration.getMinutes() + 1); // Update to 1 minute or your desired duration
      commit('setSessionExpiration', expiration);

      // Schedule a timeout to display an alert after session expiration
      setTimeout(() => {
        if (new Date() > expiration) {
          alert('Your session has expired. Please refresh the page or log in again.');
        }
      }, expiration - new Date());
    },
  },
  getters: {
    isSessionExpired: (state) => new Date() > state.sessionExpiration,
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
});
